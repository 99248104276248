import React from 'react'
import styled from 'styled-components'
import routes from '@routes'
import Box from '@ui/Box'
import { Color } from '@interfaces/designSystem'
import Flex from '@ui/Flex'
import { SocialIcon } from 'react-social-icons'
import Grid from '@ui/Grid'

const Wrap = styled.footer`
  padding: 20px;
  flex-shrink: 0;
  border-radius: 20px;
  background: #1f1f1f;
  width: 98%;
  margin: auto;
  max-width: 1200px;
  position: relative;
  display: flex;
  justify-content: space-between;

  @media (max-width: 630px) {
    margin-top: 100px;
    display: block;
  }
`

const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
`

const FooterLink = styled.a`
  text-decoration: none;
  margin: 4px 0;
  display: block;
`

const Copyright = styled.div`
  color: #fff;
  margin-top: 26px;
  line-height: 1.5;
`

const IconLink = styled.a`
  width: 56px;
  height: 56px;
  display: block;
  background: url('/img/logo-compact.svg') no-repeat center center / contain;
`

const Right = styled(Box)`
  @media (max-width: 630px) {
    margin-top: 32px;
  }
`

const Footer = () => {
  return (
    <Box bg={Color.Black} pb={26}>
      <Wrap>
        <Box>
          <Flex
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            mr={8}
          >
            <Box>
              <IconLink href={routes.publicRoutes.home} />

              <Box mt={8}>
                <FooterLink href={routes.publicRoutes.landingPricingTableForWix}>
                  Pricing table for Wix
                </FooterLink>
                <FooterLink href={routes.publicRoutes.landingPricingTableForSquarespace}>
                  Pricing table for Squarespace
                </FooterLink>
                <FooterLink href={routes.publicRoutes.landingPricingTableForElementor}>
                  Pricing table for Elementor
                </FooterLink>
                <FooterLink href={routes.publicRoutes.landingPricingTableForShopify}>
                  Pricing table for Shopify
                </FooterLink>
                <FooterLink href={routes.publicRoutes.landingPricingTableForWeebly}>
                  Pricing table for Weebly
                </FooterLink>
                <FooterLink href={routes.publicRoutes.landingPricingTableForWebflow}>
                  Pricing table for Webflow
                </FooterLink>
                <FooterLink href={routes.publicRoutes.landingPricingTableForGoogleSites}>
                  Pricing table for Google Sites
                </FooterLink>
                <FooterLink href={routes.publicRoutes.landingPricingTableForHTML}>
                  Pricing table for HTML
                </FooterLink>
              </Box>
            </Box>
            <Copyright>
              © {new Date().getFullYear()} Enhanci Ltd <br />
              <Box color={Color.Grey_50}>
                71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ{' '}
                <br />
                <a href="mailto:inbox@enhanci.com">inbox@enhanci.com</a>
              </Box>
            </Copyright>
          </Flex>
        </Box>

        <Right maxWidth={350} lineHeight="1.5">
          <Box mb={24}>
            Enhanci is creating beautiful no-code pricing tables (also FAQ sections and
            compare plans tables), showing analytics and running AB experiments to
            discover which design performs better
          </Box>
          <Box color={Color.Grey_50} mb={24}>
            <FooterLink href={routes.publicRoutes.termsOfService}>
              Terms of service
            </FooterLink>
            <FooterLink href={routes.publicRoutes.privacyPolicy}>
              Privacy policy
            </FooterLink>
            <FooterLink href={routes.publicRoutes.contact}>Contact us</FooterLink>
            <FooterLink href={routes.publicRoutes.ourStory}>Our story</FooterLink>
            <FooterLink href={routes.external.affiliate}>Earn with us</FooterLink>
          </Box>
          <Flex>
            <Box mr={16}>
              <SocialIcon
                url="https://twitter.com/enhanci"
                style={{ height: 35, width: 35 }}
                target="_blank"
              />
            </Box>
            <Box>
              <SocialIcon
                target="_blank"
                url="https://www.facebook.com/people/Enhanci/61554359578503/"
                style={{ height: 35, width: 35 }}
              />
            </Box>
          </Flex>
        </Right>
      </Wrap>
    </Box>
  )
}

export default Footer
