export default {
  // no redirects
  publicRoutes: {
    home: '/',
    termsOfService: '/terms-of-service',
    privacyPolicy: '/privacy-policy',
    widgetPreview: '/widget-preview/:id',
    abTestPreview: '/ab-test-preview/:id',
    widgetPreviewIframe: '/widget-preview-iframe',
    contact: '/contact',
    contactCustomPlan: '/contact-custom-plan',
    ourStory: '/our-story',
    pricing: '/pricing',
    blog: '/blog',
    helpCentre: '/help-centre',
    wixInstruction: '/help-centre/platforms/pricing-table-for-wix',
    squarespaceInstruction: '/help-centre/platforms/pricing-table-for-squarespace',
    webflowInstruction: '/help-centre/platforms/pricing-table-for-webflow',
    shopifyInstruction: '/help-centre/platforms/pricing-table-for-shopify',
    elementorInstruction: '/help-centre/platforms/pricing-table-for-elementor',
    weeblyInstruction: '/help-centre/platforms/pricing-table-for-weebly',
    googleSitesInstruction: '/help-centre/platforms/pricing-table-for-google-sites',
    htmlInstruction: '/help-centre/platforms/pricing-table-for-html',
    wixABInstruction: '/help-centre/pricing-ab-testing/pricing-ab-testing-for-wix',
    squarespaceABInstruction:
      '/help-centre/pricing-ab-testing/pricing-ab-testing-for-squarespace',
    webflowABInstruction:
      '/help-centre/pricing-ab-testing/pricing-ab-testing-for-webflow',
    shopifyABInstruction:
      '/help-centre/pricing-ab-testing/pricing-ab-testing-for-shopify',
    elementorABInstruction:
      '/help-centre/pricing-ab-testing/pricing-ab-testing-for-elementor',
    weeblyABInstruction: '/help-centre/pricing-ab-testing/pricing-ab-testing-for-weebly',
    googleSitesABInstruction:
      '/help-centre/pricing-ab-testing/pricing-ab-testing-for-google-sites',
    htmlABInstruction: '/help-centre/pricing-ab-testing/pricing-ab-testing-for-html',
    fullTutorial: '/help-centre/tutorials/how-to-use-enhanci-for-pricing-table',
    aiWizard: '/blog/ai-wizard',
    toolsForABPriceTesting: '/blog/tools-for-ab-price-testing',
    landingPricingTableForElementor: '/pricing-table-for-elementor',
    landingPricingTableForGoogleSites: '/pricing-table-for-google-sites',
    landingPricingTableForHTML: '/pricing-table-for-html',
    landingPricingTableForShopify: '/pricing-table-for-shopify',
    landingPricingTableForSquarespace: '/pricing-table-for-squarespace',
    landingPricingTableForWeebly: '/pricing-table-for-weebly',
    landingPricingTableForWix: '/pricing-table-for-wix',
    landingPricingTableForWebflow: '/pricing-table-for-webflow',
  },
  // redirect to platform if authenticated
  guestRoutes: {
    login: '/login',
    signup: '/signup',
    resetPassword: '/reset-password',
    verifyResetPassword: '/verify-reset-password',
  },
  // redirect to login if not authenticated
  privateRoutes: {
    builder: {
      any: '/builder/:id/:page',
      templates: '/builder/:id/templates',
      cards: '/builder/:id/cards',
      settings: '/builder/:id/settings',
      planComparison: '/builder/:id/plan-comparison',
      faqSection: '/builder/:id/faq-section',
      analytics: '/builder/:id/analytics',
      integrations: '/builder/:id/integrations',
    },
    platform: {
      widgetList: '/platform/widget-list',
      connections: '/platform/connections',
      abTests: {
        all: '/platform/ab-tests',
        create: '/platform/ab-tests/create',
        preview: '/platform/ab-tests/:id/preview',
      },
    },
    publishedWidget: '/published-widget/:id',
    verifyEmail: '/verify-email',
    profile: '/profile',
    billingPortalRedirect: '/billing-portal-redirect',
    widgetPreviewIframe: '/widget-preview-iframe',
  },
  api: {
    templates: `/templates`,
    templatesByType: `/templates/by_type`,
    defaultConfig: `/templates/default_config`,
    login: `/auth/login`,
    logout: `/auth/logout`,
    googleLogin: `/auth/google`,
    selector: {
      currencies: '/selector/currencies',
    },
    widgets: {
      all: `/widgets`,
      single: `/widgets/:id`,
      publish: `/widgets/:id/publish`,
      publishPreview: `/widgets/:id/publish-preview`,
      copy: `/widgets/:id/copy`,
      checkPublishStatus: `/widgets/:id/check-publish-status`,
      resetPublishStatus: `/widgets/:id/reset-publish-status`,
      demo: `/widgets/demo`,
      checkIsInAbTest: '/widgets/:id/check-is-in-ab-test',
    },
    stripe: {
      getInstallLink: '/stripe/oauth/install-link',
      connect: '/stripe/oauth/connect',
      disconnect: '/stripe/oauth/disconnect',
      checkConnection: '/stripe/oauth/check-connection',
      products: '/stripe/products',
      prices: '/stripe/products/:id/prices',
    },
    ai: {
      wizard: '/ai/wizard',
    },
    user: {
      resetPassword: '/users/reset-password',
      verifyResetPassword: '/users/verify-reset-password',
      requestVerifyEmail: '/users/request-verify-email',
      verifyEmail: '/users/verify-email',
      me: '/users/me',
      promoInfo: '/users/promo-info',
      update: '/users',
      create: '/users',
      createBillingPortalSession: '/users/create-billing-portal-session',
      totalMonthlyViews: '/users/total-monthly-views',
      settings: '/users/settings',
      plansInfo: '/users/plans-info',
      embedFeedbackToken: '/users/embed-feedback-token',
      collectGuestEmail: '/users/collect-guest-email',
      applyPromocode: '/users/apply-promo-code',
      requestHelp: '/users/request-help',
    },
    analytics: {
      views: '/analytics/:id/views',
      clicks: '/analytics/:id/clicks',
      conversions: '/analytics/:id/conversions',
    },
    abTests: {
      all: '/ab-tests',
      single: '/ab-tests/:id',
      finish: '/ab-tests/:id/finish',
      rePublish: '/ab-tests/:id/re-publish',
    },
  },
  external: {
    widgetScript: `${process.env.NEXT_PUBLIC_S3_BUCKET_NAME}/widgets/widget.:id.js`,
    abTestScript: `${process.env.NEXT_PUBLIC_S3_BUCKET_NAME}/ab-tests/ab-test.:id.js`,
    widgetPreviewScript: `${process.env.NEXT_PUBLIC_S3_BUCKET_NAME}/widgets/widget.preview.:id.js`,
    feedback: `https://enhanci.canny.io/general`,
    affiliate: `https://enhanci.trackdesk.com/sign-up`,
  },
}
