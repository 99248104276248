import {
  CustomStyleComponentInterface,
  CustomStyleInterface,
} from '@interfaces/designSystem'
import { BoxProps } from '@ui/Box'
import isPropValid from '@emotion/is-prop-valid'

export const getPx = (val: string | number) =>
  typeof val === 'number' ? `${val}px` : val

export const transformPropsToStyledComponentProps = (props: BoxProps) => {
  type KeysEnum<T> = { [P in keyof Required<T>]: 1 }

  const styleAttrsObj: KeysEnum<CustomStyleInterface> = {
    mt: 1,
    mb: 1,
    ml: 1,
    mr: 1,
    pt: 1,
    pb: 1,
    pl: 1,
    pr: 1,
    p: 1,
    m: 1,
    fontSize: 1,
    fontStyle: 1,
    fontWeight: 1,
    color: 1,
    textAlign: 1,
    display: 1,
    lineHeight: 1,
    width: 1,
    maxWidth: 1,
    height: 1,
    alignItems: 1,
    justifyContent: 1,
    position: 1,
    zIndex: 1,
    bg: 1,
    flexDirection: 1,
    borderRadius: 1,
    flexShrink: 1,
    cursor: 1,
    border: 1,
    overflow: 1,
    alignContent: 1,
    justifyItems: 1,
    gap: 1,
    flow: 1,
    minWidth: 1,
    right: 1,
    left: 1,
    top: 1,
    bottom: 1,
    verticalAlign: 1,
  }

  const styleAttrs = Object.keys(styleAttrsObj) as (keyof CustomStyleInterface)[]

  return Object.keys(props).reduce<CustomStyleComponentInterface>((acc, key) => {
    if (styleAttrs.includes(key as keyof CustomStyleInterface)) {
      acc[`$${key}`] = props[key]
    } else if (isPropValid(key)) {
      acc[key] = props[key]
    }

    return acc
  }, {})
}
