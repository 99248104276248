import React from 'react'
import styled, { css } from 'styled-components'
import Box, { BoxProps } from '@ui/Box'
import Header from '@components/Landing/Header'
import Footer from '@components/Landing/Footer'
import Title from '@components/Landing/Title'

const Container = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  color: #e1e1e1;
`

const limitedCss = css`
  max-width: 1200px;
  width: 98%;
  margin: auto;
`

const Content = styled.div<{ $limited?: boolean }>`
  ${({ $limited }) => $limited && limitedCss};
  margin-bottom: 32px;
`

const Bg = styled.div`
  background-color: #131313;
  flex: 1 0 auto;
`

const Banner = styled.div`
  background-color: #5c64ff;
  padding: 10px;
  margin: auto;
  font-size: 18px;
  text-align: center;

  a {
    text-decoration: underline;
    margin-left: 8px;
  }
`

interface Props extends Omit<BoxProps, 'title'> {
  children: React.ReactNode
  meta?: React.ReactNode
  title?: React.ReactNode
  limited?: boolean
}

const LandingLayout = ({ children, meta, title, limited, ...props }: Props) => {
  return (
    <>
      {meta}
      <Container {...props}>
        <Bg>
          <Content $limited={limited}>
            <Header />
            {title && <Title>{title}</Title>}
            {children}
          </Content>
        </Bg>
        <Footer />
      </Container>
    </>
  )
}

export default LandingLayout
