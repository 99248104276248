'use client'

import React from 'react'
import styled from 'styled-components'
import Box from './Box'
import {
  CustomStyleComponentInterface,
  CustomStyleInterface,
} from '../../interfaces/designSystem'
import { transformPropsToStyledComponentProps } from '@utils/designSystem'

interface Props
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color' | 'translate'>,
    CustomStyleInterface {}

const Wrap = styled(Box)<CustomStyleComponentInterface>`
  display: flex;
  ${({ $alignItems }) => $alignItems !== undefined && `align-items: ${$alignItems};`}
  ${({ $justifyContent }) =>
    $justifyContent !== undefined && `justify-content: ${$justifyContent};`}
  ${({ $flexDirection }) =>
    $flexDirection !== undefined && `flex-direction: ${$flexDirection};`}
  ${({ $display }) => $display !== undefined && `display: ${$display};`}
`

const Flex = (props: Props) => {
  return <Wrap {...transformPropsToStyledComponentProps(props)} {...props} />
}

export default Flex
