import React, { useState } from 'react'
import styled from 'styled-components'
import Box from '@ui/Box'
import routes from '@routes'
import { HiOutlineMenu } from 'react-icons/hi'
import { Color } from '@interfaces/designSystem'
import Flex from '@ui/Flex'

const StyledHeader = styled.header`
  padding-top: 34px;
`
const Logo = styled.a`
  display: block;
  background: url('/img/logo.svg') no-repeat center center / contain;
  width: 157px;
  height: 56px;
`

const Content = styled.div`
  max-width: 1200px;
  width: 98%;
  margin: auto;
  position: relative;
`

const Menu = styled.div`
  font-size: 20px;

  @media (max-width: 970px) {
    display: none;
  }
`
const MenuItem = styled.a`
  color: #fff;
  margin: 0 20px;
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const GetStarted = styled.a`
  background-color: #fff;
  color: #000000;
  border-radius: 10px;
  padding: 12px 16px;
`

const MobileMenuBtn = styled.button`
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  margin-left: 15px;
  display: none;

  @media (max-width: 970px) {
    display: block;
  }
`

const MobileMenu = styled(Box)`
  position: absolute;
  right: 3px;
  top: 65px;
  z-index: 100;
`
const MobileMenuLink = styled.a`
  font-size: 16px;
  display: block;
  color: #000;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  return (
    <StyledHeader>
      <Content>
        <HeaderContent>
          <Logo href="/" />
          <Menu>
            <MenuItem href={routes.publicRoutes.pricing}>Pricing</MenuItem>
            <MenuItem
              href={routes.privateRoutes.builder.templates.replace(':id', 'demo')}
            >
              See how it works
            </MenuItem>
            <MenuItem href="/#why-choose-us">Why choose us</MenuItem>
            <MenuItem href={routes.publicRoutes.blog}>Blog</MenuItem>
            <MenuItem href={routes.publicRoutes.helpCentre}>Help centre</MenuItem>
          </Menu>
          <Flex>
            <GetStarted href={routes.guestRoutes.signup}>Get started</GetStarted>
            <MobileMenuBtn onClick={() => setIsMobileMenuOpen(prev => !prev)}>
              <HiOutlineMenu size={34} color={Color.White} />
            </MobileMenuBtn>
            {isMobileMenuOpen && (
              <MobileMenu borderRadius={10} bg={Color.White} p={20}>
                <MobileMenuLink href={routes.publicRoutes.pricing}>
                  Pricing
                </MobileMenuLink>
                <MobileMenuLink
                  href={routes.privateRoutes.builder.templates.replace(':id', 'demo')}
                >
                  See how it works
                </MobileMenuLink>
                <MobileMenuLink href="/#why-choose-us">Why choose us</MobileMenuLink>
                <MobileMenuLink href={routes.publicRoutes.blog}>Blog</MobileMenuLink>
                <MobileMenuLink href={routes.publicRoutes.helpCentre}>
                  Help centre
                </MobileMenuLink>
              </MobileMenu>
            )}
          </Flex>
        </HeaderContent>
      </Content>
    </StyledHeader>
  )
}

export default Header
