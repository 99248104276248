'use client'

import React, { forwardRef } from 'react'
import styled from 'styled-components'
import {
  CustomStyleComponentInterface,
  CustomStyleInterface,
} from '../../interfaces/designSystem'
import { getPx, transformPropsToStyledComponentProps } from '../../utils/designSystem'

const Wrap = styled.div<CustomStyleComponentInterface>`
  ${props => props.$mt !== undefined && `margin-top: ${getPx(props.$mt)};`}
  ${props => props.$mb !== undefined && `margin-bottom: ${getPx(props.$mb)};`}
  ${props => props.$ml !== undefined && `margin-left: ${getPx(props.$ml)};`}
  ${props => props.$mr !== undefined && `margin-right: ${getPx(props.$mr)};`}
  ${props => props.$pt !== undefined && `padding-top: ${getPx(props.$pt)};`}
  ${props => props.$pb !== undefined && `padding-bottom: ${getPx(props.$pb)};`}
  ${props => props.$pl !== undefined && `padding-left: ${getPx(props.$pl)};`}
  ${props => props.$pr !== undefined && `padding-right: ${getPx(props.$pr)};`}
  ${({ $width }) => $width !== undefined && `width: ${getPx($width)};`}
  ${({ $maxWidth }) => $maxWidth !== undefined && `max-width: ${getPx($maxWidth)};`}
  ${({ $height }) => $height !== undefined && `height: ${getPx($height)};`}
  ${({ $p }) => $p !== undefined && `padding: ${getPx($p)};`}
  ${({ $m }) => $m !== undefined && `margin: ${getPx($m)};`}
  ${({ $position }) => $position !== undefined && `position: ${$position};`}
  ${({ $zIndex }) => $zIndex !== undefined && `z-index: ${$zIndex};`}
  ${({ $bg }) => $bg !== undefined && `background-color: ${$bg};`}
  ${({ $color }) => $color !== undefined && `color: ${$color};`}
  ${({ $borderRadius }) =>
    $borderRadius !== undefined && `border-radius: ${getPx($borderRadius)};`}
  ${({ $fontSize }) => $fontSize !== undefined && `font-size: ${getPx($fontSize)};`}
  ${({ $fontWeight }) => $fontWeight !== undefined && `font-weight: ${$fontWeight};`}
  ${({ $flexShrink }) => $flexShrink !== undefined && `flex-shrink: ${$flexShrink};`}
  ${({ $cursor }) => $cursor !== undefined && `cursor: ${$cursor};`}
  ${({ $border }) => $border !== undefined && `border: ${$border};`}
  ${({ $overflow }) => $overflow !== undefined && `overflow: ${$overflow};`}
  ${({ $textAlign }) => $textAlign !== undefined && `text-align: ${$textAlign};`}
  ${({ $minWidth }) => $minWidth !== undefined && `min-width: ${getPx($minWidth)};`}
  ${({ $display }) => $display !== undefined && `display: ${$display};`}
  ${({ $lineHeight }) => $lineHeight !== undefined && `line-height: ${$lineHeight};`}
  ${({ $top }) => $top !== undefined && `top: ${$top};`}
  ${({ $left }) => $left !== undefined && `left: ${$left};`}
  ${({ $right }) => $right !== undefined && `right: ${$right};`}
  ${({ $bottom }) => $bottom !== undefined && `bottom: ${$bottom};`}
  ${({ $verticalAlign }) =>
    $verticalAlign !== undefined && `vertical-align: ${$verticalAlign};`}
`

export interface BoxProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'color' | 'translate'>,
    CustomStyleInterface {
  use?: string
}

const Box = forwardRef<HTMLElement, BoxProps>((props, ref) => {
  return (
    <Wrap
      as={props.use || 'div'}
      // @ts-ignore
      ref={ref}
      {...transformPropsToStyledComponentProps(props)}
    >
      {props.children as React.ReactElement<any, any> | null}
    </Wrap>
  )
})

export default Box
