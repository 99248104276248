import React from 'react'
import styled from 'styled-components'

type Props = {
  children?: React.ReactNode
  iconLeft?: string
}

const TitleStyled = styled.h1`
  color: #f2f2f2;
  font-size: 64px;
  font-weight: 400;
  line-height: 1.2;
  position: relative;
  display: inline-block;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 48px;
    line-height: 1.1;
  }
`

const TitleIcon = styled.svg<{ $left?: string }>`
  position: absolute;
  top: -35px;
  left: ${({ $left }) => $left || '-60px'};

  @media (max-width: 950px) {
    display: none;
  }
`

const Wrap = styled.div`
  text-align: center;
  margin: 95px auto 50px;
  max-width: 880px;

  @media (max-width: 768px) {
    margin: 15px auto 35px;
  }
`

const Title = ({ children, iconLeft }: Props) => {
  return (
    <Wrap>
      <TitleStyled>
        <TitleIcon
          width="65"
          height="50"
          viewBox="0 0 65 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          $left={iconLeft}
        >
          <g id="Group 32">
            <path
              id="Vector 38"
              d="M48.793 47.9731C33.0929 47.3615 17.5545 45.1686 1.99952 43.0475"
              stroke="#FFD66D"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              id="Vector 39"
              d="M52.8965 37.3003C47.4101 29.8093 43.4517 21.5821 38.9405 13.4931"
              stroke="#FFD66D"
              strokeWidth="3"
              strokeLinecap="round"
            />
            <path
              id="Vector 40"
              d="M62.75 31C62.7497 27 62.7485 20.3517 62.7485 18.054C62.7485 12.7027 62.7485 7.35139 62.7485 2.00009"
              stroke="#FFD66D"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </g>
        </TitleIcon>
        {children}
      </TitleStyled>
    </Wrap>
  )
}

export default Title
